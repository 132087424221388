import React, { lazy, Suspense } from 'react'
import { HelmetProvider } from 'react-helmet-async'
import { QueryClient, QueryClientProvider } from 'react-query'
import { createBrowserRouter, RouterProvider } from "react-router-dom"
import './assets/styles.scss'

const queryClient = new QueryClient()

const Content = lazy(() => import('./screens/Content'))
const Previewer = lazy(() => import('./screens/ParagraphPreviewer'))
const router = createBrowserRouter([
  {
    path: "*",
    element: <Content />,
  },
  {
    path: "/paragraphs-previewer/form/:id/:name",
    element: <Previewer />,
  }
])

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <HelmetProvider>
        <Suspense >
          <RouterProvider router={router} />
        </Suspense>
      </HelmetProvider>
    </QueryClientProvider>
  )
}

export default App